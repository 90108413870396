<template>
  <v-container>
    <h2>Admin Controls</h2>
    <p>List of Accounts</p>
    <div class="d-flex">
      <v-text-field
        label="Search Account"
        v-model="search"
        prepend-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon color="primary" large @click="getAccounts" :loading="loading"
        ><v-icon>mdi-refresh</v-icon></v-btn
      >
    </div>
    <v-data-table
      :loading="loading"
      dense
      :items="users"
      :headers="headers"
      :search="search"
      class="mb-15"
    >
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex">
          <v-btn icon color="primary" @click="openDialog(item)" small>
            <v-icon>mdi-list-box</v-icon></v-btn
          >
          <v-btn icon color="error" small @click="deleteAccount(item)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </div>
      </template>
      <template v-slot:[`item.is_activated`]="{ item }">
        <p :class="item.is_activated == 1 ? 'success--text' : 'error--text'">
          {{ item.is_activated == 1 ? "Activated" : "Disabled" }}
        </p>
      </template>
      <template v-slot:[`item.user_log_ins`]="{ item }">
        <p>{{ item.user_log_ins.length }}</p>
      </template>
      <template v-slot:[`item.last_logged_in`]="{ item }">
        <p>{{ item.last_login }}</p>
      </template>
    </v-data-table>
    <!-- LAZY -->

    <account-dialog-modal
      :item="selectedItem"
      v-if="enableModal"
      @close="enableModal = false"
    />
    <div v-if="show_confirm">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="proceed_deletion"
        @cancel-event="show_confirm = false"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import ConfirmNotification from "@/components/ConfirmNotification.vue";
export default {
  components: {
    accountDialogModal: () =>
      import("../components/AdminControls/accountDialogModal.vue"),
    ConfirmNotification,
  },
  data() {
    return {
      show_confirm: false,
      alert_message: "",
      loading: false,
      search: "",
      enableModal: false,
      selectedItem: null,
      users: [],
      headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Account Name", value: "name" },
        { text: "Username", value: "username" },
        { text: "Province", value: "province" },
        { text: "City/Municipality", value: "city_or_municipality" },
        { text: "Barangay", value: "barangay" },
        // { text: "Secret Question", value: "secret_question" },
        // { text: "Secret Answer", value: "secret_answer" },
        // { text: "Expiration Date", value: "expiration_date" },
        // { text: "Activation Date", value: "activation_date" },
        { text: "Account Status", value: "is_activated" },
        { text: "Log-in Count", value: "user_log_ins" },
        { text: "Last Logged in", value: "last_logged_in" },
      ],
    };
  },
  created() {
    this.getAccounts();
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),
    deleteAccount(item) {
      this.show_confirm = true;
      this.selectedItem = item;
      this.alert_message = "Are you sure you want to delete this account?";
    },
    proceed_deletion() {
      this.loading = true;
      axios.delete("/user/" + this.selectedItem.id).then(() => {
        this.show_confirm = false;
        this.set_notification({
          message: "You have successfully deleted an item",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.loading = false;
        // remove the item from the array
        let filtered = this.users.filter((m) => m.id != this.selectedItem.id);
        this.users = filtered;
      });
    },
    getAccounts() {
      this.loading = true;
      axios.get("/users").then((r) => {
        console.log(r.data);
        this.users = r.data;
        this.loading = false;
      });
    },
    openDialog(item) {
      this.selectedItem = item;
      this.enableModal = true;
    },
  },
};
</script>

<style scoped>
.v-data-table ::v-deep td {
  font-size: 13px !important;
}
/* .v-data-table-header th {
  white-space: nowrap;
  text-align: center;
} */
</style>
