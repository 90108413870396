import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/storage";
import Chart from "chart.js";
import moment from "moment";

require("@/store/subscriber");
Vue.config.productionTip = false;

var firebaseConfig = {
  apiKey: "AIzaSyCCFAnbgbWZgzhVao0PWQ5Sutl7ySAiumY",
  authDomain: "campco-cims.firebaseapp.com",
  projectId: "campco-cims",
  storageBucket: "campco-cims.appspot.com",
  messagingSenderId: "12155837044",
  appId: "1:12155837044:web:6e770c0de37a3625878f21",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

axios.defaults.baseURL = "https://accessserver.kpcms.online/api";
// axios.defaults.baseURL = "http://192.168.1.39:8000/api";
// axios.defaults.baseURL = "http://localhost:8000/api";

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    Chart,
    moment,

    render: (h) => h(App),
  }).$mount("#app");
});
