<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="dialog = true"
      >Amicable Settlement</v-btn
    >

    <v-dialog v-model="dialog" max-width="370px" persistent>
      <v-card flat>
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">
            notice of execution
          </h3></v-sheet
        >
        <v-card-text>
          <div class="mt-6 d-flex justify-center">
            <p class="mr-4 mt-4 font-weight-bold">Date of Settlement</p>
            <input type="date" v-model="other_data.amicable_date" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="deep-purple lighten-4" @click="dialog = false">
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      dialog: false,

      other_data: {},
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    print() {
      var date = moment(this.other_data.amicable_date);
      this.other_data.day = date.format("Do"); //outputs day with suffix
      this.other_data.month = date.format("MMMM"); //outputs complete month
      this.other_data.year = date.format("Y");

      window.open(
        this.print_url +
          this.user.id +
          "/amicable_settlement/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(this.other_data)
      );
    },
  },
  created() {
    this.other_data.amicable_date = moment().format("Y-MM-DD");
  },
};
</script>
