import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Dashboard from "../views/Dashboard.vue";
import Arbitration from "../views/Arbitration.vue";
import Conciliation from "../views/Conciliation.vue";
import Directory from "../views/Directory.vue";
import Execution from "../views/Execution.vue";
import FAQs from "../views/FAQs.vue";
import FilingOfComplaint from "../views/FilingOfComplaint.vue";
import Forms from "../views/Forms.vue";
import Mediation from "../views/Mediation.vue";
import Reports from "../views/Reports.vue";
import UserInformation from "../views/UserInformation";
import Signin from "../views/Signin.vue";
import ComplaintProfile from "../views/ComplaintProfile.vue";
import Unsettled from "../views/Unsettled.vue";
import AdminControl from "../views/AdminControl.vue";
import Questions from "../components/FilingOfComplaintManagement/Questions.vue";
import EditComplaint from "../views/EditComplaint.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },

  {
    path: "/arbitration",
    name: "Arbitration",
    component: Arbitration,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/conciliation",
    name: "Conciliation",
    component: Conciliation,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/directory",
    name: "Directory",
    component: Directory,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/execution",
    name: "Execution",
    component: Execution,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: FAQs,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/filingofcomplaint",
    name: "Filing Of Complaint",
    component: FilingOfComplaint,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/forms",
    name: "Forms",
    component: Forms,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/mediation",
    name: "Mediation",
    component: Mediation,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/userinformation",
    name: "User Information",
    component: UserInformation,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/unsettled",
    name: "Unsettled",
    component: Unsettled,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/complaintprofile/:id",
    name: "Complaint Profile",
    component: ComplaintProfile,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },

  {
    path: "/editcomplaint/:id",
    name: "Edit Complaint",
    component: EditComplaint,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },

  {
    path: "/questions/:id",
    name: "questions",
    component: Questions,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },

  {
    path: "/admincontrols",
    name: "AdminControl",
    component: AdminControl,
    beforeEnter: (to, from, next) => {
      if (
        !store.getters["auth/authenticated"] ||
        store.getters["auth/user"].id != 1
      ) {
        alert("Error: Unauthorized");
        console.log(store.getters["auth/authenticated"]);
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
