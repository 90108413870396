<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat class="pa-8 rounded-card mb-10 mt-n12">
          <v-card-text>
            <v-row class="mb-3">
              <v-col>
                <span class="subtitle-1 deep-purple--text accent-4">
                  {{ language == "English" ? english_heading : bisaya_heading }}
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row dense>
              <v-col v-if="language == 'English'">
                <v-switch
                  v-for="item in englishQuestions"
                  :disabled="item.hide"
                  v-model="item.value"
                  color="deep-purple accent-4"
                  :key="item.id"
                  :label="item.question"
                  dense
                  class="caption"
                  hide-details=""
                  @change="change_switch_english(item)"
                >
                </v-switch>
                <v-divider class="mt-5 mb-2"></v-divider>
              </v-col>
              <v-col v-else>
                <v-switch
                  v-for="item in bisayaQuestions"
                  :disabled="item.hide"
                  v-model="item.value"
                  color="deep-purple accent-4"
                  :key="item.id"
                  :label="item.question"
                  dense
                  class="caption"
                  hide-details=""
                  @change="change_switch_bisaya(item)"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :loading="button_loading"
              depressed
              class="rounded-xl"
              dark
              @click="submit"
              color="deep-purple accent-4"
              width="100px"
            >
              Proceed
            </v-btn>
            <v-btn
              depressed
              class="rounded-xl black--text"
              color="yellow"
              width="100px"
              @click="back"
            >
              Back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-dialog
        persistent
        v-model="show_question_3_sub_question"
        width="350"
        class="ma-auto"
      >
        <v-card>
          <v-card-text>
            <h3 class="text-center pt-5">
              {{
                language == "English" ? english_subquestion : bisaya_subquestion
              }}
            </h3>
            <div class="d-flex justify-center mt-3">
              <v-btn
                color="deep-purple accent-4 white--text"
                class="mr-2"
                @click="proceedFiling"
                >Yes</v-btn
              >
              <v-btn @click="false_question_3_sub_question">No</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <agency-modal
      :dialog="agency_dialog"
      @close="agency_dialog = false"
      :grounds="finalFalseStatementArray"
    />
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import ProgressLinear from "../ProgressLinear.vue";
import { mapActions, mapGetters } from "vuex";
import AgencyModal from "./AgencyModal.vue";
// import moment from "moment";
export default {
  components: {
    ProgressLinear,
    AgencyModal,
  },
  data() {
    return {
      englishQuestions: [
        {
          id: "1",
          question:
            "1. Does the dispute involve parties who actually reside in barangays of SAME city or municipality?",
          value: false,
          grounds:
            "One or both parties actually reside in barangays of different cities or municipalities",
          hide: false,
        },

        {
          id: "2",
          question:
            "2. Or, where such barangay units adjoin each other, do the parties thereto agree to submit their differences to amicable settlement by an appropriate Lupon?",
          value: false,
          hide: false,
          grounds:
            "The parties does not agree to submit their differences to amicable settlement by an appropriate lupon",
        },
        {
          id: "3",
          question:
            "3. Is any of the parties NOT a government, or any subdivision or instrumentality thereof?",
          value: false,
          hide: false,
          grounds:
            "One party is the government or any subdivisions or instrumentality thereof.",
        },
        {
          id: "4",
          question:
            "4. Is any of the parties NOT a public officer or employee, and the dispute relates NOT to the performance of his or her official function?",
          value: false,
          hide: false,
          grounds:
            "One party is a public officer or employee, and the dispute relates to the performance of his official functions",
        },
        {
          id: "5",
          question:
            "5. Is the offense subject of the complaint punishable by a maximum penalty of imprisonment NOT exceeding one (1) year or a fine NOT over five thousand pesos (P5,000.00)?",
          value: false,
          hide: false,
          grounds:
            "The offense involved is punishable by imprisonment exceeding one (1) year or a fine exceeding Five thousand pesos (P5,000.00).",
        },
        {
          id: "6",
          question: "6. Does the above offense offend a private party?",
          value: false,
          hide: false,
          grounds: "Offense involves no private offended party.",
        },
        {
          id: "7",
          question:
            "7. Does the dispute involve real properties located in different cities and municipalities?",
          value: false,
          hide: false,
          grounds:
            "Dispute involves real properties located in different cities or municipalities.",
        },
        {
          id: "8",
          question:
            "8. Is the complaint filed NOT by or against corporations, partnership or juridical entities?",
          value: false,
          hide: false,
          grounds: "One or both parties is a juridical person.",
        },

        {
          id: "9",
          question:
            "9. Is the Respondent NOT under police custody or detention?",
          value: false,
          hide: false,
          grounds: "Respondent is under custody or dentention.",
        },
        {
          id: "10",
          question:
            "10. Is the Complaint coupled with probational remedies such as preliminary injunction, attachment, delivery of personal property, and support pendente lite",
          value: false,
          hide: false,
          grounds: "Complaint is coupled with probational remedies.",
        },
        {
          id: "11",
          question: "11. Is the Complaint bared by the statute of limitations",
          value: false,
          hide: false,
          grounds: "Complaint is barred by the statute of limitations.",
        },
        {
          id: "12",
          question:
            "12. Does the dispute arise NOT from the Comprehensive Agrarian Reform Law?",
          value: false,
          hide: false,
          grounds: "Dispute arises from the Comprehensive Agrarian Reform Law.",
        },
        {
          id: "13",
          question:
            "13. Does the dispute or controversy arise NOT from employer-employee relations?",
          value: false,
          hide: false,
          grounds: "Dispute arises from employer-employee relations.",
        },
        {
          id: "14",
          question:
            "14. Does the dispute NOT involve an action to annul judgment upon a compromise?",
          value: false,
          hide: false,
          grounds:
            "Dispute involves action to annul judgment upon a compromise.",
        },
        {
          id: "15",
          question: "15. Does the complainant already pay the filing fee?",
          value: false,
          hide: false,
          grounds: "The complainant did not pay the filing fee",
        },
      ],
      bisayaQuestions: [
        {
          id: "1",
          question:
            "1. Ang panagbangi ba naglakip og mga partido nga nagpuyo sa barangay nga ANAA sa MAU NG siyudad o munisipyo?",
          value: false,
          grounds:
            "Usa o duha ka partido ang nagpuyo sa lahi nga siyudad o munisipyo.",
          hide: false,
        },

        {
          id: "2",
          question:
            "2. Kon ang mga barangay nga gilakip sa panagbangi nagtapad, ang mga partido ba nagkauyon nga ipasubay sa Lupon ang ilang panagkalainan aron makab-ot ang kasabutan?",
          value: false,
          hide: false,
          grounds: "",
        },
        {
          id: "3",
          question:
            "3. Ang bisan kinsa ba sa mga partido DILI usa ka gobyerno, o bisan unsang yunit o ahensya niini?",
          value: false,
          hide: false,
          grounds:
            "Usa ka partido ang gobyerno o bisan unsang yunit o ahensya niini.",
        },
        {
          id: "4",
          question:
            "4. Ang bisan kinsa ba sa mga partido DILI usa ka opisyal o empleyado sa gobyerno, ug ang panagbangi DILI may kalabutan sa iyang opisyal nga katungdanan?",
          value: false,
          hide: false,
          grounds:
            "Usa ka partido ang usa ka opisyal o empleyado sa gobyerno, ug ang panagbangi may kalabutan sa iyang opisyal nga katungdanan.",
        },
        {
          id: "5",
          question:
            "5. Ang sala ba nga gihisgotan sa reklamo silotan og pinakataas nga pagkabilanggo nga DILI molapas sa usa (1) ka tuig o multa nga DILI molapas sa lima ka libo pesos (P5,000.00)?",
          value: false,
          hide: false,
          grounds:
            "Ang sala naglakip sa silot nga molapas sa usa (1) ka tuig nga pagkabilanggo o multa nga molapas sa lima ka libo pesos (P5,000.00).",
        },
        {
          id: "6",
          question:
            "6. Ang maong sala ba nakaapekto sa usa ka pribadong partido?",
          value: false,
          hide: false,
          grounds: "Ang sala walay pribadong nabiktima.",
        },
        {
          id: "7",
          question:
            "7. Ang panagbangi ba naglakip og yuta nga anaa sa lahi-lahing siyudad ug munisipyo?",
          value: false,
          hide: false,
          grounds:
            "Ang panagbangi naglakip og yuta nga anaa sa lahi-lahing siyudad o munisipyo.",
        },
        {
          id: "8",
          question:
            "8. Ang reklamo ba DILI gisang-at batok o alang sa mga korporasyon, partnership, o uban pang legal nga entidad?",
          value: false,
          hide: false,
          grounds: "Usa o duha ka partido usa ka juridical nga entidad.",
        },

        {
          id: "9",
          question:
            "9. Ang Respondent ba WALA sa kustodiya o kapriso sa kapulisan?",
          value: false,
          hide: false,
          grounds: "Ang Respondent anaa sa kustodiya o kapriso.",
        },
        {
          id: "10",
          question:
            "10. Ang reklamo ba naglakip sa probisyonal nga remedyo sama sa preliminary injunction, attachment, delivery sa personal nga butang, ug suporta samtang nagpadayon ang kaso?",
          value: false,
          hide: false,
          grounds: "Ang reklamo naglakip sa probisyonal nga remedyo.",
        },
        {
          id: "11",
          question: "11. Ang reklamo ba napugngan sa balaod sa limitasyon?",
          value: false,
          hide: false,
          grounds: "Ang reklamo napugngan sa balaod sa limitasyon.",
        },
        {
          id: "12",
          question:
            "12. Ang panagbangi ba WALA naggikan sa Comprehensive Agrarian Reform Law?",
          value: false,
          hide: false,
          grounds:
            "Ang panagbangi naggikan sa Comprehensive Agrarian Reform Law.",
        },
        {
          id: "13",
          question:
            "13. Ang panagbangi ba WALA naggikan sa relasyon tali sa employer ug empleyado?",
          value: false,
          hide: false,
          grounds:
            "Ang panagbangi naggikan sa relasyon tali sa employer ug empleyado.",
        },
        {
          id: "14",
          question:
            "14. Ang panagbangi ba WALA naglakip sa pagpakansela sa desisyon sa kaso nga base sa kasabutan?",
          value: false,
          hide: false,
          grounds:
            "Ang panagbangi naglakip sa pagpakansela sa desisyon sa kaso nga base sa kasabutan.",
        },
        {
          id: "15",
          question: "15. Ang nagreklamo ba nakabayad na sa filing fee?",
          value: false,
          hide: false,
          grounds: "Wala makabayad ang nagreklamo sa filing fee",
        },
      ],
      finalFalseStatementArray: null,
      english_subquestion:
        "Do the parties thereto agree to submit their difference to amicable settlement by an appropriate Lupon?",
      english_heading:
        "Some guide questions in determining whether the barangay has jurisdiction over the parties and the subject matter of the complaint pursuant to the Revised Rules on the Katarungang Pambarangay, to wit:",
      bisaya_subquestion:
        "Agree ba ang mga partido nga magpadayon sa ilang kinauyonan sa pagpamatuod pinaagi sa usa ka maayong pamaagi sa pagpamatuod sa usa ka angay nga Lupon?",
      bisaya_heading:
        "Pipila ka giya nga mga pangutana sa pagtino kung ang barangay adunay hurisdiksyon sa mga partido ug sa hilisgutan sa reklamo subay sa Revised Rules sa Katarungang Pambarangay:",
      show_question_3_sub_question: false,
      button_loading: false,
      agency_dialog: false,
      agency: "",
      value: "true",
      progress_dialog: false,
      mediator: new Array(),
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      complaint: "complaint/complaint",
      mediation: "mediation/mediation",
      pre_file_complaint: "complaint/pre_file_complaint",
      language: "auth/language",
    }),
  },

  created() {
    if (!this.pre_file_complaint) this.back();
    // this.questions = this.kp_questions;
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      add_complaint: "complaint/add_complaint",
      add_mediation: "mediation/add_mediation",
      get_mediations: "mediation/get_mediations",
      get_notifications: "notification/get_notifications",
      add_cases_handled: "auth/add_cases_handled",
      add_handled: "lupon/add_handled",
    }),
    false_question_3_sub_question() {
      // this.questions[2].value = false;
      this.show_question_3_sub_question = false;
      this.set_notification({
        message:
          "The case is not within the jurisdiction of Katarungang Pambarangay",
        color: "error",
        showing: true,
        icon: "mdi-error",
      });

      this.englishQuestions[6].value = false;
      this.bisayaQuestions[6].value = false;
    },
    back() {
      this.$router.go(-1);
    },
    proceedFiling() {
      this.show_question_3_sub_question = false;
    },
    change_switch_english(item) {
      if (item.id == "7" && item.value == true) {
        this.show_question_3_sub_question = true;
      }

      const questionOne = this.englishQuestions.find((q) => q.id === "1");
      const questionTwo = this.englishQuestions.find((q) => q.id === "2");

      // If question 1 is enabled, disable question 2, and vice versa
      if (item.id === "1") {
        questionTwo.hide = item.value;
      } else if (item.id === "2") {
        questionOne.hide = item.value;
      }
    },
    change_switch_bisaya(item) {
      console.log("bisaya");
      if (item.id == "7" && item.value == true) {
        this.show_question_3_sub_question = true;
      }

      const questionOne = this.bisayaQuestions.find((q) => q.id === "1");
      const questionTwo = this.bisayaQuestions.find((q) => q.id === "2");

      // If question 1 is enabled, disable question 2, and vice versa
      if (item.id === "1") {
        questionTwo.hide = item.value;
      } else if (item.id === "2") {
        questionOne.hide = item.value;
      }
    },

    revert_question_value() {
      this.englishQuestions.forEach((q) => {
        q.value = false;
      });
      this.bisayaQuestions.forEach((q) => {
        q.value = false;
      });
    },
    async submit() {
      // console.log(this.englishQuestions);
      this.button_loading = true;
      //if some of the questions are false except 1 and 2, return true
      var is_not_complete = false;
      // get false statements and store them in an array
      var falseStatementsArr = [];
      if (this.language == "English") {
        is_not_complete = this.englishQuestions.some(
          (q) => q.value === false && !["1", "2"].includes(q.id)
        );
        this.englishQuestions.forEach((q) => {
          if (q.value == false && !["1", "2"].includes(q.id)) {
            // store grounds to array
            falseStatementsArr.push(q.grounds);
            this.finalFalseStatementArray = falseStatementsArr;
          }
        });
      } else {
        is_not_complete = this.bisayaQuestions.some(
          (q) => q.value === false && !["1", "2"].includes(q.id)
        );
        this.bisayaQuestions.forEach((q) => {
          if (q.value == false && !["1", "2"].includes(q.id)) {
            // store grounds to array
            falseStatementsArr.push(q.grounds);
            this.finalFalseStatementArray = falseStatementsArr;
          }
        });
      }

      if (is_not_complete) {
        this.button_loading = false;

        // console.log(this.questions);
        return (this.agency_dialog = true);
      }
      // this reverts the questions array to the original status which is false.
      this.revert_question_value();
      await this.add_complaint(this.pre_file_complaint);
      await this.get_notifications();

      this.button_loading = false;
      this.set_notification({
        message: "You have successfully added a new complaint.",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
      this.$router.push(
        "/complaintprofile/" + this.pre_file_complaint.exclusive_case_no
      );
    },
  },
};
</script>
<style scoped>
.v-switch .v-label {
  font-size: 7px;
}

.rounded-card {
  border-radius: 20px;
}
</style>
