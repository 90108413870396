<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Notice of Execution</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">
            notice of execution
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <v-text-field
              dense
              v-model="form.name_of_party_obliged"
              label="Name of party obliged"
            ></v-text-field>
            <br />
            <v-text-field
              dense
              v-model="form.amount"
              label="State amount of settlement of award"
            ></v-text-field>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      json_dialog: false,
      date_settled_dialog: false,
      form: {
        name_of_party_obliged: "",
        amount: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),

    input_other_data() {
      this.json_dialog = true;
    },
    print() {
      let other_data = {
        name_of_party_obliged: this.form.name_of_party_obliged,
        amount: this.form.amount,
      };
      window.open(
        this.print_url +
          this.user.id +
          "/notice_of_execution/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
  created() {
    this.form.date_settled = this.complaint.settled.date_settled;
    this.form.agreement = this.complaint.settled.agreement;
  },
};
</script>
